import qs from "qs";
import { getStrapiURL } from "./api-helpers";

export async function fetchAPI(path: string, urlParamsObject = {}, options = {}) {
  try {
    // Merge default and user options
    const mergedOptions = {
      next: { revalidate: 5 },
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    };

    // Build request URL
    let queryString = qs.stringify(urlParamsObject);
    queryString = queryString.replaceAll("filters=", "filters");
    const requestUrl = `${getStrapiURL(`/api${path}${queryString ? `?${queryString}` : ""}`)}`;
    // Trigger API call
    const response = await fetch(requestUrl, mergedOptions);
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
