export function getStrapiURL(path = "") {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"}${path}`;
}

export function getStrapiMedia(url: string | null) {
  if (url == null) {
    return null;
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith("http") || url.startsWith("//")) {
    return url;
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${getStrapiURL()}${url}`;
}

export function formatDate(dateString: string, long: boolean = false, time: boolean = false) {
  const date = new Date(dateString);

  // Extract components manually
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Dutch month names
  const monthNames = ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];

  // Format the date part using Dutch conventions
  let formattedDate = `${day} ${monthNames[monthIndex]}`;
  if (long) {
    formattedDate += ` ${year}`;
  }

  if (time) {
    // Formatting time with leading zeros if needed, typical Dutch formatting uses 24-hour clock without leading zero
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
    // Manually adding the comma here to ensure consistency, typically Dutch time is written after date without comma
    formattedDate += `, ${formattedTime}`;
  }

  return formattedDate;
}

// ADDS DELAY TO SIMULATE SLOW API REMOVE FOR PRODUCTION
export const delay = (time: number) => new Promise((resolve) => setTimeout(() => resolve(1), time));
