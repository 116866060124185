import Link from "next/link";
import Image from "next/image";
import { TagVariant } from "@/lib/types";
import Tag from "./ui/tag";
import { Button } from "./ui/button";
import Arrow from "./icons/Arrow";
import { cva } from "class-variance-authority";
import Video from "./Video";
import { cn, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import { formatDate } from "@/lib/api-helpers";

interface CardProps {
  variant: TagVariant;
  urlVariant?: string;
  square?: boolean;
  item: any;
}

const imageVariants = cva("relative  w-full overflow-hidden rounded", {
  variants: {
    variant: {
      Speerpunt: "md:aspect-[1.6] aspect-[1.6] max-h-[250px] md:h-[250px]",
      Nieuws: "aspect-square max-h-[250px]",
      "Over ons": "aspect-square max-h-[250px]",
      Video: "h-[190px]",
      download: "aspect-[0.9] max-h-[300px]",
      square: "aspect-square max-h-[250px]",
      detail: "aspect-[0.9] max-h-[300px]",
    },
  },
  defaultVariants: {
    variant: "Speerpunt",
  },
});

const Card = ({ item, variant, square, urlVariant }: CardProps) => {
  let { title, thumbnail, tags, slug, role, file, name, type, poster, url, date, publishedAt } = item.attributes;
  if (tags && Array.isArray(tags)) {
    if (tags[0]?.name) {
      tags = tags.map((tag: any) => tag.name);
    }
  }

  const getLinkText = (variant: TagVariant | "download" | "detail") => {
    switch (variant) {
      case "Speerpunt":
        return "Klik hier om dit speerpunt te lezen";
      case "Nieuws":
        return "Klik hier om dit artikel te lezen";
      case "Over ons":
        return "Klik hier om meer te lezen";
      case "Europa":
        return "Lees meer over NSC Europa";
      case "download":
        return "Klik open en lees";
      case "Video":
        return "Klik hier om de video te bekijken";
      case "detail":
        return "Klik open en lees";
    }
  };

  const getUrl = (variant: string) => {
    if (variant === "Over ons") variant = type;
    switch (variant) {
      case "Nieuws":
        return `/nieuws/${slug}`;
      case "nieuws":
        return `/nieuws/${slug}`;
      case "Speerpunt":
        return `/speerpunten/${slug}`;
      case "speerpunt":
        return `/speerpunten/${slug}`;
      case "download":
        return strapiMediaUrl(file);
      case "tweede-kamerfractie":
        return `/tweede-kamerfractie/${slug}`;
      case "onze-bewindspersonen":
        return `/onze-bewindspersonen/${slug}`;
      case "bestuur-partij-bureau":
        return `/bestuur-partij-bureau/${slug}`;
      case "Bestaanszekerheid":
      case "detail":
        return url;
      default:
        return `/${slug}`;
    }
  };

  return (
    <LinkWrapper
      variant={variant}
      external={variant === "download"}
      href={getUrl(urlVariant || variant)}
      className="group h-full"
    >
      <article className="flex h-full flex-col gap-5">
        <figure
          className={imageVariants({
            variant: square ? "square" : (variant as "Speerpunt" | "Nieuws" | "Over ons" | "Video"),
          })}
        >
          {variant === "Video" && file ? (
            <Video src={strapiMediaUrl(file)} poster={strapiMediaUrl(poster)} />
          ) : thumbnail.data ? (
            <Image
              src={strapiMediaUrl(thumbnail)}
              alt={strapiMediaAlt(thumbnail)}
              className="rounded object-cover transition-transform duration-500 group-hover:scale-105"
              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
              fill
            />
          ) : (
            <div className="h-full w-full bg-gray-300" />
          )}
        </figure>
        {tags && tags.length > 0 && variant !== "Over ons" && (
          <div className="flex flex-wrap items-center gap-2">
            {tags.map((tag: any) => (
              <Tag variant={tag} key={Math.random()} />
            ))}
            {variant === "Nieuws" && (
              <span className="translate-y-[1px] text-sm font-light">{formatDate(date || publishedAt, true)}</span>
            )}
          </div>
        )}
        <h3
          className={cn(
            variant === "Over ons" ? "!normal-case" : "",
            "max-w-[300px] text-balance font-oswald text-xl font-bold uppercase !leading-normal"
          )}
        >
          {title || name}
        </h3>
        {role && <p className="support -mt-2.5">{role}</p>}

        <Button variant="link" size="link" className="mt-auto text-wrap text-start">
          {getLinkText(variant)}{" "}
          <Arrow
            color={variant === "download" || variant === "detail" ? "var(--white)" : "var(--dark-blue)"}
            className="ml-3 transition-transform group-hover:translate-x-1"
          />
        </Button>
      </article>
    </LinkWrapper>
  );
};

const LinkWrapper = ({
  children,
  href,
  external,
  className,
  variant,
}: {
  children: React.ReactNode;
  external?: boolean;
  href: string;
  className?: string;
  variant: TagVariant;
}) => {
  return variant === "Video" ? (
    children
  ) : external ? (
    <a href={href} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link className={className} href={href}>
      {children}
    </Link>
  );
};

export default Card;
