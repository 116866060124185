"use client";

import RichText from "../RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import DonationForm from "../donation/DonationForm";

const DonationSection = ({ data }: any) => {
  return (
    <section className="py-16 md:py-32">
      <div className="grid-container">
        <div className="order-first col-span-12 md:hidden">
          {data?.description && (
            <RichText data={data.description} className="rich-text mb-0 border-[rgba(23,28,96,0.4)] pb-8" />
          )}
        </div>
        <div className="col-span-12 mt-16 md:col-span-6 md:mt-0 lg:col-span-5">
          {data?.description && (
            <RichText
              data={data.description}
              className="rich-text mb-0 hidden border-b border-[rgba(23,28,96,0.4)] pb-8 md:block"
            />
          )}
          <Accordion type="single" collapsible className="w-full">
            {data.faq &&data.faq.items.map((item: any) => (
              <AccordionItem key={item.id} value={item.id}>
                <AccordionTrigger className="header-3 !border-none pb-8 data-[state=open]:pb-0">
                  {item.title}
                </AccordionTrigger>
                <AccordionContent>
                  {item?.description && <RichText data={item.description} className="rich-text" />}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="relative left-[-10px] order-first col-span-12 h-fit w-screen bg-dark-blue px-[10px] py-16 md:left-0 md:order-last md:col-span-6 md:col-start-7 md:w-full md:rounded md:p-10 lg:p-16">
          <h2 className="header-3 mb-8 text-white md:text-[42px]">{data?.formTitle}</h2>
          <DonationForm />
        </div>
      </div>
    </section>
  );
};

export default DonationSection;
