"use client";

import { cn, getBgColor, getTextColor } from "@/lib/utils";
import { getStrapiURL } from "@/lib/api-helpers";
import DynamicForm from "../DynamicForm";

const QuestionForm = ({ data, noContainer }: any) => {
  const handleSubmit = async (formData: FormData) => {
    const rawFormData = {
      name: formData.get("name"),
      email: formData.get("email"),
      message: formData.get("message"),
      type: "question",
      meta: {
        source: "website",
        url: "https://www.nieuwsociaalcontract.nl",
        title: "Nieuw Sociaal Contract",
      },
    };

    return fetch(`${getStrapiURL(`/api/nsc-submissions`)}`, {
      method: "POST",
      body: JSON.stringify({
        data: rawFormData,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const formFields = [
    {
      id: "name",
      label: "Voornaam*",
      type: "text",
      required: true,
      errorMessage: "Voornaam is verplicht in te vullen",
      colSpan: "md:col-span-6",
    },
    {
      id: "email",
      label: "E-mailadres*",
      type: "email",
      required: true,
      errorMessage: "E-mail is verplicht in te vullen",
      autoComplete: "email",
      colSpan: "md:col-span-6",
    },
    // {
    //   id: "subject",
    //   label: "Onderwerp*",
    //   type: "select",
    //   required: true,
    //   errorMessage: "Onderwerp is verplicht in te vullen",
    //   options: [
    //     {
    //       id: "subject1",
    //       label: "subject1",
    //     },
    //     {
    //       id: "subject2",
    //       label: "subject2",
    //     },
    //     {
    //       id: "subject3",
    //       label: "subject3",
    //     },
    //     {
    //       id: "subject4",
    //       label: "subject4",
    //     },
    //   ],
    // },
    { id: "message", label: "Vraag*", type: "textarea" },
  ];

  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
      }}
    >
      <div className={cn("mx-auto py-20 md:py-24", !noContainer && "grid-container container")}>
        <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
          <h2
            style={{
              color: getTextColor(data.backgroundColor),
            }}
            className={cn("header-2 mb-8 !text-balance text-center", data.backgroundColor === "white" && "text-start")}
          >
            {data?.title}
          </h2>
          <DynamicForm
            textNotWhite
            fields={formFields}
            onSubmit={handleSubmit}
            buttonLabel="Vraag versturen"
            buttonBackgroundColor={data.buttonBackgroundColor}
            buttonFit
            sendMessage="Bedankt voor uw vraag. We nemen zo snel mogelijk contact met u op."
          />
        </div>
      </div>
    </section>
  );
};

export default QuestionForm;
