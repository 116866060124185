"use client";

import { cva } from "class-variance-authority";

import { TagVariant } from "@/lib/types";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
import { useStore } from "@/store";

interface TagProps {
  variant: TagVariant;
  rounded?: boolean;
  noText?: boolean;
}

const tagVariants = cva(
  "font-light flex gap-2 items-center font-roboto after:relative after:block after:translate-y-px after:h-[11px] after:w-[11px] after:rounded-full",
  {
    variants: {
      variant: {
        Agenda: "after:bg-mint",
        Nieuws: "after:bg-mint",
        "Jong Sociaal Contract": "after:bg-ice-blue",
        Europa: "after:bg-blue",
        "Goed bestuur": "after:bg-yellow",
        Bestaanszekerheid: "after:bg-yellow",
        Wonen: "after:bg-yellow",
        Migratie: "after:bg-yellow",
        "Internationale stabiliteit": "after:bg-yellow",
        "Gezonde leefomgeving": "after:bg-yellow",
        "Digitale leefomgeving": "after:bg-yellow",
        Gezondheidszorg: "after:bg-yellow",
        Mensen: "after:bg-yellow",
        "Over ons": "after:bg-orange",
        Speerpunt: "after:bg-yellow",
      },
    },
    defaultVariants: {
      variant: "Agenda",
    },
  }
);

const roundedTagVariants = cva(
  "rounded-full flex items-center justify-center text-[14px] font-roboto font-medium w-6 h-6 md:w-10 md:h-10",
  {
    variants: {
      variant: {
        Agenda: "bg-mint text-dark-blue",
        Nieuws: "bg-mint text-dark-blue",
        "Jong Sociaal Contract": "bg-ice-blue text-dark-blue",
        Europa: "bg-blue text-white",
        "Goed bestuur": "bg-yellow text-dark-blue",
        Bestaanszekerheid: "bg-yellow text-dark-blue",
        Wonen: "bg-yellow text-dark-blue",
        Migratie: "bg-yellow text-dark-blue",
        "Internationale stabiliteit": "bg-yellow text-dark-blue",
        "Gezonde leefomgeving": "bg-yellow text-dark-blue",
        "Digitale leefomgeving": "bg-yellow text-dark-blue",
        Gezondheidszorg: "bg-yellow text-dark-blue",
        Mensen: "bg-yellow text-dark-blue",
        "Over ons": "bg-orange text-dark-blue",
        Speerpunt: "bg-yellow text-dark-blue",
        "Een goed pensioen": "bg-yellow text-dark-blue",
      },
    },
    defaultVariants: {
      variant: "Agenda",
    },
  }
);

const getTagInitials = (variant: TagVariant) => {
  switch (variant) {
    case "Agenda":
      return "A";
    case "Speerpunt":
      return "SP";
    case "Over ons":
      return "O";
    case "Nieuws":
      return "N";
    case "Jong Sociaal Contract":
      return "JSC";
    case "Europa":
      return "EU";
    case "Goed bestuur":
      return "GB";
    case "Bestaanszekerheid":
      return "BZ";
    case "Wonen":
      return "W";
    case "Migratie":
      return "M";
    case "Internationale stabiliteit":
      return "IS";
    case "Gezonde leefomgeving":
      return "GL";
    case "Digitale leefomgeving":
      return "DL";
    case "Gezondheidszorg":
      return "GZ";
    case "Mensen":
      return "M";
    case "Een goed pensioen":
      return "P";
    default:
      return "";
  }
};

const Tag = ({ variant, rounded, noText }: TagProps) => {
  const { tagsData } = useStore();
  return rounded ? (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={roundedTagVariants({ variant: variant as any })}>{getTagInitials(variant as TagVariant)}</div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{(tagsData.find((tag: any) => tag.tag_id === variant) as any)?.tag_info}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <p className={tagVariants({ variant: variant as any })}>{noText ? "" : variant}</p>
  );
};

export default Tag;
