"use client";
import { useRouter } from "next/navigation";
import { motion } from "framer-motion";

import { ScrollArea } from "@radix-ui/react-scroll-area";
import { ScrollBar } from "./ui/scroll-area";

const NewsFilter = ({ activeFilter, uniqueTags }: { activeFilter: string | string[], uniqueTags: string[] }) => {
  const router = useRouter();

  const FILTERS = [
    "Het laatste nieuws",
    ...uniqueTags,
  ];

  return (
    <ScrollArea type="auto" className="w-full overflow-auto">
      <div className="flex w-fit flex-nowrap gap-8 md:mx-auto">
        {FILTERS.map((filter: string) => (
          <div key={filter} className="flex items-center gap-2">
            {filter === activeFilter && (
              <motion.div className="h-[11px] w-[11px] rounded-full bg-mint" layoutId="activeFilter" />
            )}
            <motion.button
              layout
              onClick={() => router.push(`/nieuws?filter=${filter}`, { scroll: false })}
              style={{
                fontWeight: filter === activeFilter ? "600" : "300",
              }}
              className="text-nowrap font-roboto text-base font-light"
            >
              {filter}
            </motion.button>
          </div>
        ))}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};

export default NewsFilter;
