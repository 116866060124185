"use client";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../ui/carousel";
import Card from "../Card";
import { getBgColor } from "@/lib/utils";

interface DetailBlockSliderProps {
  data: any;
}

const DetailBlockSlider = ({ data }: DetailBlockSliderProps) => {
  const [api, setApi] = useState<CarouselApi>();

  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
      }}
      className="w-full overflow-x-hidden bg-dark-blue py-12 text-white md:pb-24 md:pt-16"
    >
      <div className="grid-container container">
        <div className="col-span-12 mx-auto mb-12 flex max-w-[700px] flex-col gap-6 md:items-center md:text-center">
          <h2 className="header-2">{data.title}</h2>
          <p className="body-xl">{data.subtitle}</p>
        </div>
        <Carousel
        opts={{
          align: "start",
        }}
        className="col-span-12 w-screen md:w-auto" setApi={setApi}>
          <CarouselContent className="-ml-5 mb-12">
            {data.items.map((item: any) => (
              <CarouselItem key={item.id} className={"basis-[90%] pl-5 md:basis-[33.333%] lg:basis-[25%]"}>
                <Card
                  variant="detail"
                  item={{
                    attributes: {
                      title: item.title,
                      thumbnail: item.thumbnail,
                      url: item.url,
                    },
                  }}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex w-[calc(100vw-20px)] items-center justify-between md:w-full">
            <CarouselPrevious />
            <div className="flex justify-center gap-2">
              {count > 1 &&
                Array.from({ length: count }).map((_, index) => (
                  <motion.button
                    aria-label={`Scroll naar slide ${index + 1}`}
                    onClick={() => api?.scrollTo(index)}
                    animate={{ opacity: current === index + 1 ? 1 : 0.5 }}
                    key={index}
                    className="h-4 w-4 rounded-full bg-white p-0"
                  />
                ))}
            </div>
            <CarouselNext />
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default DetailBlockSlider;
