"use client";

import { TagVariant } from "@/lib/types";
import { cn, getUniqueTags } from "@/lib/utils";
import { useState } from "react";
import { getSliderTitle } from "./Slider";
import Tag from "../ui/tag";
import Arrow from "../icons/Arrow";
import RichText from "../RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { ButtonLink } from "../ui/button";

const VacancyArchiveSection = ({ data }: any) => {
  const items = data.items.map((item: any) => {
    return {
      ...item,
      attributes: {
        ...item.attributes,
        tags: item.attributes.tags.map((tag: any) => {
          return tag.name;
        }),
      },
    };
  });

  //group by location
  const groupedByLocation = items.reduce((acc: any, item: any) => {
    const location = item.attributes.location;
    if (!acc[location]) {
      acc[location] = [];
    }
    acc[location].push(item);
    return acc;
  }, {});

  const locations = Object.keys(groupedByLocation);
  let uniqueTags: TagVariant[] = getUniqueTags(items);
  uniqueTags = getUniqueTags(items);

  const [openItem, setOpenItem] = useState(locations[0]);

  return (
    <section className="mb-16 md:mb-24">
      {/* Desktop */}
      <div className="hidden grid-cols-12 gap-x-5 md:grid">
        <div className="col-span-12 mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
          <h2 className="header-3 !text-nowrap">Vacatures</h2>
          <div className="h-px w-full flex-1 bg-dark-blue opacity-40" />
          <div className="flex gap-2">
            {uniqueTags.map((tag) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div>
        </div>
        <div className="col-span-4">
          {locations.map((item: any) => (
            <div
              key={item.id}
              className="group flex flex-col gap-5 border-b border-[rgba(23,28,96,0.4)] py-6 md:col-span-6 md:flex-row md:items-center md:gap-5"
            >
              <button
                className="flex max-w-[400px] flex-1 items-start gap-2 text-start font-roboto font-bold text-dark-blue"
                onClick={() => setOpenItem(item)}
              >
                <span>{item}</span>
              </button>
              <Arrow
                direction={openItem === item ? "down" : "right"}
                className={cn(
                  "ml-auto transition-transform group-hover:translate-x-1",
                  openItem === item ? "!translate-x-2 rotate-90" : ""
                )}
              />
            </div>
          ))}
        </div>
        <div className="col-span-8 col-start-6">
          <>
            {openItem &&
              groupedByLocation[openItem].map((item: any) => (
                <div key={item.id} className="group flex flex-col gap-5 py-6 md:col-span-6 md:gap-5">
                  <h2 className="header-3 mb-6">{item.attributes.title}</h2>
                  <p className="body !leading-[27px]">{item.attributes.shortdescription}</p>
                  <ButtonLink href={`/vacatures/${item.attributes.slug}`}>Bekijk de vacature hier</ButtonLink>
                </div>
              ))}
          </>
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden">
        <div className="col-span-12 mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
          <h2 className="header-3 !text-nowrap">Vacatures</h2>
          <div className="h-px w-full flex-1 bg-dark-blue opacity-40" />
          <div className="flex gap-2">
            {uniqueTags.map((tag) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div>
        </div>

        <Accordion type="single" collapsible className="w-full">
          {locations.map((item: any) => (
            <AccordionItem key={item} value={item}>
              <AccordionTrigger>{item}</AccordionTrigger>
              <AccordionContent>
                {groupedByLocation[item].map((item: any) => (
                  <div key={item.id} className="group flex flex-col gap-2 py-6 md:col-span-6 md:gap-5">
                    <h2 className="header-3 mb-4">{item.attributes.title}</h2>
                    <p className="body !leading-[27px]">{item.attributes.shortdescription}</p>
                    <ButtonLink className="mt-4" href={`/vacatures/${item.attributes.slug}`}>Bekijk de vacature hier</ButtonLink>
                  </div>
                ))}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default VacancyArchiveSection;
