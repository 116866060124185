export const FALLBACK_SEO = {
  title: "Nieuw Sociaal Contract",
  description:
    "We zijn iets nieuws begonnen: een nieuwe politiek partij. Daarmee gaan we met een mooie groep mensen werken aan uitvoering van onze plannen op het gebied…",
};

export const PROVINCES = [
  "Noord-Holland",
  "Zuid-Holland",
  "Utrecht",
  "Zeeland",
  "Overijssel",
  "Gelderland",
  "Flevoland",
  "Drenthe",
  "Noord Brabant",
  "Limburg",
  "Groningen",
  "Friesland",
];

export const MOCK_SPEERPUNTEN = [
  {
    id: 1,
    slug: "nsc-in-gesprek-over-een-houdbare-migratie-in-nederland-en-europa",
    title: "NSC in gesprek over een houdbare migratie in Nederland en europa",
    tags: ["europa"],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
  {
    id: 2,
    slug: "samenwonen-kinderen-krijgen-verhuizen-lees-over-onze-aanpak-van-de-woningcrisis",
    title: "samenwonen? kinderen krijgen? Verhuizen? Lees over onze aanpak van de woningcrisis",
    tags: ["jong_nsc"],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
  {
    id: 3,
    slug: "zonnepanelen-op-daken-en-niet-op-vruchtbare-landbouwgrond-een-gezonde-leefomgeving",
    title: "zonnepanelen op daken en niet op vruchtbare landbouwgrond. Een gezonde leefomgeving",
    tags: [],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
  {
    id: 4,
    slug: "zonnepanelen-op-daken-en-niet-op-vruchtbare-landbouwgrond-een-gezonde-leefomgeving",
    title: "zonnepanelen op daken en niet op vruchtbare landbouwgrond. Een gezonde leefomgeving",
    tags: [],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
];

export const MOCK_NEWS = [
  {
    id: 1,
    createdAt: "2023-03-01T00:00:00.000Z",
    slug: "nsc-in-gesprek-over-een-houdbare-migratie-in-nederland-en-europa",
    title: "Uitslag Europese verkiezingen in Nederland gelijk aan prognose",
    tags: ["over_ons"],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
  {
    id: 2,
    createdAt: "2023-03-01T00:00:00.000Z",
    slug: "onze-beoogde-bewindspersonen-deze-mensen-gaan-het-namens-nsc-doen",
    title: "Onze beoogde bewindspersonen. Deze mensen gaan het namens NSC doen!",
    tags: ["europa"],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
  {
    id: 3,
    createdAt: "2023-03-01T00:00:00.000Z",
    slug: "wie-nsc-zegt-zegt-bestaanszekerheid-en-goed-bestuur-ook-in-europa",
    title: "Wie NSC zegt, zegt Bestaanszekerheid en Goed bestuur. Ook in Europa.",
    tags: ["europa"],
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
  },
];

export const MOCK_PEOPLE = [
  {
    id: 1,
    slug: "pieter-omtzigt",
    title: "Pieter Omtzigt",
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
    role: "Partijleider",
  },
  {
    id: 2,
    slug: "sandra-palmen-schlangen",
    title: "Sandra Palmen-Schlangen",
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
    role: "Minister van Onderwijs, Cultuur en Weterschap",
  },
  {
    id: 3,
    slug: "nicolien-van-vroonhoven-kok",
    title: "Nicolien van Vroonhoven-Kok",
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
    role: "Minister van Buitenlandse Zaken",
  },
  {
    id: 4,
    slug: "tjebben-van-oostenbruggen",
    title: "Tjebben van Oostenbruggen",
    image: {
      url: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "De eerste speerpunt",
    },
    role: "Staatssecretaris van Financiën ",
  },
];

export const MOCK_CALENDAR = [
  {
    date: new Date("2024-07-01T00:00:00.000Z"),
    title: "Ledendag: Zomer editie 2024",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum consequat amet orci non egestas egestas. Et nunc egestas at egestas pellentesque. Urna sit ac platea.",
    location: "Amsterdam",
    tags: ["over_ons"],
  },
  {
    date: new Date("2024-07-05T00:00:00.000Z"),
    title: "Debatavond: café de ondernemer",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum consequat amet orci non egestas egestas. Et nunc egestas at egestas pellentesque. Urna sit ac platea.",
    location: "Amsterdam",
    tags: ["europa"],
  },
  {
    date: new Date("2024-07-15T00:00:00.000Z"),
    title: "Informatieavond Tweede kamerfractie",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum consequat amet orci non egestas egestas. Et nunc egestas at egestas pellentesque. Urna sit ac platea.",
    location: "Amsterdam",
    tags: ["jong_nsc"],
  },
  {
    date: new Date("2024-08-12T00:00:00.000Z"),
    title: "Inschrijving geopend ledendag: Herfst 2024",
    description:
      "Lorem ipsum dolor sit amet consectetur. Elementum consequat amet orci non egestas egestas. Et nunc egestas at egestas pellentesque. Urna sit ac platea.",
    location: "Amsterdam",
    tags: ["over_ons"],
  },
];

export const MOCK_DOWNLOADS = [
  {
    id: 1,
    title: "Download document",
    url: "download",
    image: {
      url: "https://images.unsplash.com/photo-1554629947-334ff61d85dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "Download document",
    },
  },
  {
    id: 2,
    title: "Download document",
    url: "download",
    image: {
      url: "https://images.unsplash.com/photo-1554629947-334ff61d85dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "Download document",
    },
  },
  {
    id: 3,
    title: "Download document",
    url: "download",
    image: {
      url: "https://images.unsplash.com/photo-1554629947-334ff61d85dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "Download document",
    },
  },
  {
    id: 4,
    title: "Download document",
    url: "download",
    image: {
      url: "https://images.unsplash.com/photo-1554629947-334ff61d85dc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      alternativeText: "Download document",
    },
  },
];

export const MOCK_VIDEOS = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet consectetur. Suspendisse.",
    tags: ["over_ons"],
    video: {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    },
  },
  {
    id: 2,
    title: "Lorem ipsum dolor sit amet consectetur. Dolor sed lacus.",
    tags: ["nieuws"],
    video: {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    },
  },
  {
    id: 3,
    title: "Lorem ipsum dolor sit amet consectetur. Dolor sed lacus.",
    tags: ["europa"],
    video: {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    },
  },
  {
    id: 4,
    title: "Lorem ipsum dolor sit amet consectetur. Dolor sed lacus.",
    tags: ["jong_nsc"],
    video: {
      url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    },
  },
];
