"use client";

import { useRef, useState } from "react";

import { Button } from "./ui/button";
import Play from "./icons/Play";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "./ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import { cn } from "@/lib/utils";

interface VideoProps {
  src: string;
  poster?: string;
  className?: string;
}

const Video = ({ src, poster, className }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Dialog
      open={isPlaying}
      onOpenChange={(value: boolean) => {
        setIsPlaying(value);
      }}
    >
      <DialogTrigger asChild>
        <div className="relative h-full w-full cursor-pointer" role="group" aria-label="Video">
          <AnimatePresence initial={false}>
            {!isPlaying && (
              <motion.div
                exit={{ opacity: 0 }}
                transition={{ delay: 0.1 }}
                className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2"
              >
                <Button
                  aria-label={isPlaying ? "Pauzeer video" : "Start video"}
                  className="h-12 w-12"
                  variant="default"
                  size="round"
                >
                  <motion.div
                    initial={{ x: -40, scale: 0 }}
                    animate={{ x: 0, opacity: 1, scale: 1 }}
                    exit={{ x: 40, scale: 0 }}
                    className=""
                  >
                    <Play />
                  </motion.div>
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
          <video
            controls={false}
            poster={poster}
            ref={videoRef}
            src={src}
            className={cn(
              "h-full w-full rounded object-cover transition-transform duration-500 group-hover:scale-105",
              className
            )}
          />
        </div>
      </DialogTrigger>
      <DialogContent className="aspect-video max-w-[90vw] overflow-hidden p-0">
        <DialogTitle className="sr-only">Video bekijken</DialogTitle>
        <video
          controls={true}
          src={src}
          className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-105"
        />
      </DialogContent>
    </Dialog>
  );
};

export default Video;
